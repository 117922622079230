import { DicPipe } from './pipes/dic.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'angular2-useful-swiper';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { HasFilledPipe } from './pipes/has-filled.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PhoneMaskDirective } from './directives/phone-masc.directive';
import { ModalComponent } from './components/modal/modal.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { ClassRequestComponent } from './components/class-request/class-request.component';
import { ClassLoginComponent } from './components/login/login.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ClubSearchComponent } from './components/club-search/club-search.component';
import { DurationPipe } from './pipes/duration.pipe';
import { CheckTodayPipe } from './pipes/check-today.pipe';
import { EmailLoginComponent } from './components/login/email-login/email-login.component';
import { PhoneLoginComponent } from './components/login/phone-login/phone-login.component';
import { LoginAndPasswordLoginComponent } from './components/login/login-and-password-login/login-and-password-login.component';
import { PushLoginComponent } from './components/login/push-login/push-login.component';
import { RegistrationComponent } from './components/login/registration/registration.component';
import { RestorePasswordComponent } from './components/login/restore-password/restore-password.component';
import { ClubDownTimeFilterPipe } from './pipes/club-down-time-filter.pipe';

@NgModule({
    imports: [
    CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SwiperModule
    ],
    declarations: [
        HasFilledPipe,
        DurationPipe,
        CheckTodayPipe,
        ClickOutsideDirective,
        PhoneMaskDirective,
        DropdownComponent,
        ModalComponent,
        PreloaderComponent,
        ClassRequestComponent,
        ClassLoginComponent,
        SpinnerComponent,
        ClubSearchComponent,
        DicPipe,
        EmailLoginComponent,
        PhoneLoginComponent,
        LoginAndPasswordLoginComponent,
        PushLoginComponent,
        RegistrationComponent,
        RestorePasswordComponent,
        ClubDownTimeFilterPipe
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        SwiperModule,
        HasFilledPipe,
        DurationPipe,
        DicPipe,
        CheckTodayPipe,
        ClickOutsideDirective,
        PhoneMaskDirective,
        DropdownComponent,
        ModalComponent,
        SpinnerComponent,
        PreloaderComponent,
        ClassRequestComponent,
        ClassLoginComponent,
        ClubSearchComponent,
        ClubDownTimeFilterPipe
    ]
})
export class SharedModule { }
